import { FilterDefaults } from '@mindfulchefuk/components/Search/Filters/types'

interface RecipeSearchFilterConfig {
  /** The slug in the dynamic URL used to match the config */
  slug: string
  filter: FilterDefaults
}

interface Config {
  /** Recipe search page config for `/*` */
  baseUrl: RecipeSearchFilterConfig[]
  /** Recipe search page config for `/healthy-recipes/*` */
  healthyRecipes: RecipeSearchFilterConfig[]
}

/**
 * Default search filter settings for search pages
 *
 * @usage
 *    `baseUrl` config is for `/*` urls
 *
 *    eg,
 *      {
 *        slug: 'healthy-summer-recipes',
 *        filter: {
 *          season: 'Summer',
 *        }
 *      }
 *
 *    is for `/healthy-summer-recipes`, showing `<SearchPage>` with the default `season` filter of `Summer`
 * 
 *    `healthyRecipes` config is similar for `/healthy-recipes/*` urls
 *    
 *    eg,
 *      {
 *        slug: 'indian-cuisine',
 *        filter: {
 *          cuisine: 'Indian',
 *        }
 *      }
 * 
 *    is for `/healthy-recipes/indian-cuisine`, showing `<SearchPage>` with the default `cuisine` filter of `Indian`
 `
 */
export const recipeSearchPageFilterDefaults: Config = {
  baseUrl: [
    // Season recipes
    {
      slug: 'healthy-summer-recipes',
      filter: {
        season: 'Summer',
      },
    },
    {
      slug: 'healthy-autumn-recipes',
      filter: {
        season: 'Autumn',
      },
    },
    {
      slug: 'healthy-winter-recipes',
      filter: {
        season: 'Winter',
      },
    },
    {
      slug: 'healthy-spring-recipes',
      filter: {
        season: 'Spring',
      },
    },

    // Cooking time recipes
    {
      slug: 'easy-recipes-15-minutes',
      filter: {
        cook_time_minutes: '10:15',
      },
    },
    {
      slug: 'easy-recipes-30-minutes',
      filter: {
        cook_time_minutes: '25:35',
      },
    },
    {
      slug: 'easy-recipes-45-minutes',
      filter: {
        cook_time_minutes: '35:45',
      },
    },

    // Primary food group recipes
    {
      slug: 'healthy-pescatarian-recipes',
      filter: {
        primary_food_group: 'Fish',
      },
    },
    {
      slug: 'healthy-shellfish-recipes',
      filter: {
        primary_food_group: 'Shellfish',
      },
    },
    {
      slug: 'healthy-poultry-recipes',
      filter: {
        primary_food_group: 'Poultry',
      },
    },
  ],
  healthyRecipes: [
    // Cuisine recipes
    {
      slug: 'indian-cuisine',
      filter: {
        cuisine: 'Indian',
      },
    },
    {
      slug: 'italian-cuisine',
      filter: {
        cuisine: 'Italian',
      },
    },
    {
      slug: 'british-cuisine',
      filter: {
        cuisine: 'British',
      },
    },
    {
      slug: 'mexican-cuisine',
      filter: {
        cuisine: 'Mexican',
      },
    },
    {
      slug: 'asian-cuisine',
      filter: {
        cuisine: 'Asian',
      },
    },
    {
      slug: 'mediterranean-cuisine',
      filter: {
        cuisine: 'Mediterranean',
      },
    },
    {
      slug: 'middle-eastern-cuisine',
      filter: {
        cuisine: 'Middle Eastern',
      },
    },
    {
      slug: 'european-cuisine',
      filter: {
        cuisine: 'European',
      },
    },
    {
      slug: 'south-american-cuisine',
      filter: {
        cuisine: 'South American',
      },
    },
    {
      slug: 'north-american-cuisine',
      filter: {
        cuisine: 'North African',
      },
    },
  ],
}
